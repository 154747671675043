(function($) {

    $(window).on('load', function () {

        // Components loading animations
        $('.view-animation').viewportChecker({
            classToAdd: 'animated',
            offset: 20
        });

        // Accordion for FAQs (jQuery)
        $('.accordion dt.active').next().slideDown()

        $('.accordion').on('click', 'dt', function () {
            $('.accordion dd').slideUp();

            if (!$(this).hasClass('active')) {
                // remove active class from any others that might be active
                $('.accordion dt.active').removeClass('active');
                $(this).addClass('active');
                $(this).next().slideDown();
            } else {
                $(this).removeClass('active');
            }
        });

        $('.seoreadmore').click(function(e){
            e.preventDefault();
            var thisSeoContainer = $('.seo-container', $(this).closest('section'));
            $(this).hide();
            thisSeoContainer.show();
            $('html,body').animate({scrollTop: thisSeoContainer.offset().top - $('.site-header').outerHeight()});
        }); 
        $('.seoreadless').click(function(e){
            e.preventDefault();
            var thisSeoContainer = $('.seo-container', $(this).closest('section'));
            $('.seoreadmore').show();
            thisSeoContainer.hide();
            $('html,body').animate({scrollTop: $(this).closest('section').offset().top});
        }); 

        // Inline Video Funcionality
        $(document).on('click', '.inline-video-trigger', function () {
            if ($(this).data('video-id')) {
                if ($(this).hasClass('vimeo')) {
                    var iframeHTML = '<iframe src="https://www.vimeo.com/embed/' + $(this).attr('data-video-id') + '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
                } else {
                    var iframeHTML = '<iframe src="https://www.youtube.com/embed/' + $(this).attr('data-video-id') + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                }

                $(this).parent('.video-preview-container').find('.inline-video-trigger').hide();
                $(this).parent('.video-preview-container').find('.overlay').hide();
                $(this).parent('.video-preview-container').find('iframe').remove();
                $(this).parent('.video-preview-container').append(iframeHTML);
            } else {
                console.error('no video ID provided.');
            }
        });

        // Phone Concatenation Script For Tracking
        setTimeout(function () {
            $('.phone-text em').each(function () {
                var unsliced = $(this).text();
                var sliced = unsliced.slice(0, -2) + "...";
                $(this).text(sliced);
                var linked = "tel:" + unsliced.replace(/\s/g, '');
                $(this).click(function () {
                    if ($(window).width() < 1000) {
                        window.location.href = linked;
                    } else {
                        $(this).text(unsliced);
                    }
                });
            });

        }, 3000);

    });

    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('.site-header').addClass('scrolled');
        } else {
            $('.site-header').removeClass('scrolled');
        }
    });

    $('.header-hamburger').click(function(e){
        e.preventDefault();

        $('body').toggleClass('menu-open');
    });

    var wpcf7Elm = $( '.wpcf7' );
    if(wpcf7Elm.length){
        wpcf7Elm[0].addEventListener( 'wpcf7mailsent', function( event ) {
            var thisform = $(event.target);
            var thisredirect = $('input[name="redirect"]', thisform);

            if(thisredirect.length){
                window.location.href = thisredirect.val();
            }
        }, false );
    }


    // Smooth Scroll To Anchor
    $(document).on('click', 'a[href*="#"]:not([href="#"])', function (event) {
        event.preventDefault()
        var target = $(this).attr('href');

        if(!$(target).length){
            if($(this).closest('.header-menu').length){
                window.location.href = '/'+target;
            }
        }else{
            $('body').removeClass('menu-open');

            $('html,body').animate({
                scrollTop : $(target).offset().top - $('.site-header').outerHeight()
            }, 1500);
        }


    });

})( jQuery );